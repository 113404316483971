/**
 *
 * @param { Number } duration
 * @param { Date } startDate
 * @returns {{weeks: [{dimanche: number, samedi: number}, {lundi: number, dimanche: number, vendredi: number, mercredi: number, jeudi: number, samedi: number, mardi: number}], month: string, year: string}[]}
 */
import { useWeeksGenerator } from "./useWeeksGenerator";

export function useMonthsGenerator (duration, startDate) {

    let arr = []
    const month = startDate.getMonth()
    let y = startDate.getFullYear()
    let m

    for (let i = month; i < month + duration; i++) {
        if (i === 12) {
            m = 0
            y++
        }
        else if (i > 12) m++
        else m = i
        arr.push({
            month: m,
            year: y,
            weeks: useWeeksGenerator(m, y)
        })
    }

    return arr

}