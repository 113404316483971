import React, { useContext } from "react";
import { GlobalContext } from "../DatePicker";

export function ItemSwitch ({ view, viewsLength }) {

    const { state, dispatch } = useContext(GlobalContext)

    function onSelectedItem () {
        dispatch({type: 'SELECT_VIEW', payload: view})
    }

    const selectedClass = viewsLength < 2 ? 'hide' : ( view.selected ? 'selected': '' )

    return (
        <li
            className={selectedClass}
            onClick={onSelectedItem}>
            {view.selected && (
                <input type="hidden" name="type_date" value={view.type}/>
            )}
            { view.label }
        </li>
    )
}