import React, { useContext, useEffect } from "react"
import { GlobalContext } from "../DatePicker";

export function Day ({ date, active }) {

    const { state, dispatch }  = useContext(GlobalContext)
    const { startDate, endDate, hoverDate } = state.selection
    const views = state.views
    const selectedView = views.find(view => view.selected)

    const classList = []

    let now = new Date()
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    if (date < now) {
        classList.push('ing-day-passed')
    }
    if (startDate === null && endDate === null && hoverDate !== null && hoverDate.getTime() === date.getTime()) {
        classList.push('ing-day-hover')
    }
    if (startDate !== null && startDate.getTime() === date.getTime()) {
        classList.push('ing-day-selected')
    }
    if (endDate !== null && endDate.getTime() === date.getTime()) {
        classList.push('ing-day-selected')
    }
    if (startDate !== null && endDate !== null) {
        if (date.getTime() > startDate.getTime() && date.getTime() < endDate.getTime()) {
            classList.push('ing-day-between-select')
        }
    }

    let durationOk = true;
    if (startDate !== null && hoverDate !== null && endDate === null) {
        let diff = Math.floor(( hoverDate - startDate ) / 86400000);

        durationOk = !selectedView.constraint.duration
            || (selectedView.constraint.duration 
            && selectedView.constraint.duration.length 
            && selectedView.constraint.duration.indexOf(Math.abs(diff)) !== -1);

        if(durationOk === true){
            if (date.getTime() > startDate.getTime() && date.getTime() <= hoverDate.getTime()) {
                classList.push('ing-day-between-select')
            }
            if (date.getTime() < startDate.getTime() && date.getTime() >= hoverDate.getTime()) {
                classList.push('ing-day-between-select')
            }
        }
    }
    if (selectedView.constraint.dayStart !== undefined && selectedView.constraint.dayStart !== null && date.getDay() !== selectedView.constraint.dayStart) {
        classList.push('ing-day-disable')
    }

    let dateIsoSplit = date.toISOString().split('T')[0];
    let authorizedDates = selectedView.constraint.dates || [];// ['2021-12-18', '2021-12-25', '2021-12-26', '2022-01-01', '2022-01-02', '2022-01-08', '2022-01-09'];
    if (selectedView.type === 'NN' && authorizedDates.indexOf(dateIsoSplit) === -1) {
        classList.push('ing-day-disable')
    }

    if (active === false) {
       classList.push('ing-day-disable')
    }

    function onSelectDay() {
        if (isDisableDay() === false && durationOk === true){
            dispatch({type: 'SELECT_DATE', payload: date})
        }
    }

    function onHoverDay() {
        if (isDisableDay() === false) {
            dispatch({type: 'HOVER_DATE', payload: date})
        }
    }

    function onHoverOutDay() {
        if (isDisableDay() === false) {
            dispatch({type: 'HOVER_DATE', payload: null})
        }
    }

    function isDisableDay () {
        if (date < now) {
            return true
        }
        let dateIsoSplit = date.toISOString().split('T')[0];
        if (selectedView.type === 'NN' && authorizedDates.indexOf(dateIsoSplit) === -1) {
            return true
        }    
        if (selectedView.constraint.dayStart && date.getDay() !== selectedView.constraint.dayStart) {
            return true
        }
        if (active === false) {
            return true
        }
        
        return false
    }

    return (
        <div
            className={classList.join(' ')}
            onClick={onSelectDay}
            onMouseOver={onHoverDay}
            onMouseOut={onHoverOutDay}
        >
            { date.getDate() }
        </div>
    )
}