import React, { useContext } from "react";
import { GlobalContext } from "../DatePicker";
import { ItemSwitch } from "./ItemSwitch";
import './DatePickerSwitch.css'

export function DatePickerSwitch () {

    const { state } = useContext(GlobalContext)

    let itemSwitch = state.views.map((view, index) =>
        <ItemSwitch
            view={view}
            viewsLength={state.views.length}
            key={index}
        />
    )

    return (
        <ul className="ing-container-switch">
            { itemSwitch }
        </ul>
    )
}