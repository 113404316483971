export function useWeeksGenerator(month, year) {
    let duration = new Date (year, month + 1, 0, 12).getDate()
    let weeks = []
    let week = {}
    let day
    for (let i = 1; i <= duration; i++) {
        let currentDate = new Date (year, month, i, 12)
        day = currentDate.getDay()
        week[`day_${day}`] = currentDate
        if (day === 0 || duration === i) {
            weeks.push({...week})
            week = {}
        }
    }
    return weeks

}