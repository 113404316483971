import React from 'react'
import ReactDOM from 'react-dom'
import { DatePicker } from './DatePicker'
import moment from "moment";

const initialState = {
    lang: 'fr',
    labels: null,
    duration: null,
    selection: {
        startDate: null,
        endDate: null,
        hoverDate: null
    },
    views: [
        {
            type: 'LL',
            label: null,
            selected: false,
            constraint: {
                dayStart: null,
                disableFromToday: 0,
                duration: {
                    min: 1,
                    max: 30
                }
            }
        }
    ],
    onMonthChange: null,
    datesPossibles: [],
    isNightPrestataire: false
}

export class DatePickerClient {

    constructor (params) {
        this.selector = params.selector
        this.referer = params.referer
        this.lang = params.lang
        this.labels = params.labels
        this.duration = params.duration
        this.views = params.views
        this.defaultParams = params.defaultParams
        this.isNightPrestataire = params.defaultParams.isNightPrestataire
        this.onMonthChange = params.onMonthChange
        this.fakeInputs = {}

        this.el = document.createElement('div')
        this.el.setAttribute('class', 'ing-datepicker')

        console.log('constructor DatePickerClient');
    }

    getValuesContext = () => ({
        lang: this.lang,
        labels: this.labels,
        duration: this.duration,
        views: this.views,
        onMonthChange: this.onMonthChange,
        isNightPrestataire: this.isNightPrestataire
    })

    init () {
        this.el.style.visibility = 'hidden'

        this.referer.deb.addEventListener('click', this.onInputStartClick)
        this.referer.fin.addEventListener('click', this.onInputEndClick)

        const container = document.querySelector(this.selector)
        container.parentElement.appendChild(this.el)

        this.buildFakeInputs();
        ReactDOM.render(this.buildApp(), this.el)
    }

    buildApp() {
        let startDate = this.referer.deb.value
        let endDate = this.referer.fin.value
        if (this.defaultParams.datedeb) startDate = this.defaultParams.datedeb
        if (this.defaultParams.datefin) endDate = this.defaultParams.datefin
        const momentDates = {
            startDate: moment(startDate, 'DD/MM/YYYY'),
            endDate: moment(endDate, 'DD/MM/YYYY')
        }

        if (this.defaultParams.duree) {
            momentDates.endDate = momentDates.startDate.clone();
            momentDates.endDate.add(parseInt(this.defaultParams.duree), 'days');
        }

        momentDates.startDate.set('h', 12);
        momentDates.endDate.set('h', 12);

        const initState = {
            ...initialState,
            ...this.getValuesContext(),
            selection: {
                startDate: momentDates.startDate.toDate(),
                endDate: momentDates.endDate.toDate(),
            },
            ...this.defaultParams.paramsLiaison
        }
        return (
            <DatePicker
                initialState={initState}
                close={this.close}
                onSelectionChange={this.onSelectionChange}
                onGetDispatch={(dispatch) => {
                    this.dispatch = dispatch
                }}
            />
        );
    }

    buildFakeInputs() {
        const buildInput = (name, referer) => {
            this.fakeInputs[name] = document.createElement('input')
            this.fakeInputs[name].addEventListener('focus', () => referer.focus())
            this.fakeInputs[name].addEventListener('click', () => referer.click())
            this.fakeInputs[name].classList.add('datepicker')
            this.fakeInputs[name].setAttribute('type', 'text')
            referer.parentElement.insertBefore(this.fakeInputs[name], referer)
            referer.style.display = 'none'
        }
        buildInput('startDate', this.referer.deb)
        buildInput('endDate', this.referer.fin)
    }

    formatDate = (date) => {
        const day = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`
        const month = date.getMonth() + 1 > 9 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
    }

    onInputStartClick = (e) => {
        e.stopPropagation()
        this.el.classList.toggle('deb')
        if (this.el.style.visibility === 'hidden') {
            this.open()
        } else if (this.el.classList.contains('fin')) {
            this.el.classList.remove('fin')
        } else {
            this.close()
        }
    }

    onInputEndClick = (e) => {
        e.stopPropagation()
        this.el.classList.toggle('fin')
        if (this.el.style.visibility === 'hidden') {
            this.open()
        }
        else if (this.el.classList.contains('deb')) {
            this.el.classList.remove('deb')
        } else {
            this.close()
        }
    }

    onSelectionChange = (selection) => {
        if (selection.startDate) {
            this.fakeInputs.startDate.value = moment(selection.startDate).locale(this.lang).format('ddd Do MMM YYYY')
            this.referer.deb.value = this.formatDate(selection.startDate)
        }
        if (selection.endDate) {
            this.close()
            this.fakeInputs.endDate.value = moment(selection.endDate).locale(this.lang).format('ddd Do MMM YYYY')
            this.el.classList.remove('deb')
            this.el.classList.remove('fin')
            this.referer.fin.value = this.formatDate(selection.endDate)
        }
    }

    filtreDates = dates => {
        this.dispatch({type: 'FILTER_DATES', payload: dates})
    }

    open = () => {
        this.el.style.visibility = 'visible'
        this.el.style.display = 'block'
        this.el.style.zIndex = '100'
    }

    close = (e) => {
        if (e && e.target === this.fakeInputs.startDate) return
        if (e && e.target === this.fakeInputs.endDate) return
        this.el.style.visibility = 'hidden'
        this.el.style.display = 'none'
        this.el.style.zIndex = '0'
    }
}