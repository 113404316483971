import React, { useContext } from 'react'
import { Day } from "./Day";
import moment from 'moment'
import { GlobalContext } from '../DatePicker'

export function Month({ month, year, weeks }) {

    const { state } = useContext(GlobalContext)

    let activeDays = state.datesPossibles.map(d => d.dates || []).reduce((s, v) => [...s, ...v], []);

    const listDays = []
    for (let i = 1; i < 8; i++) {
        listDays.push(moment().day(i).locale(state.lang).format('dd'))
    }

    const weeksComponent = weeks.map((dates, key) =>
        <div className="ing-week" key={key}>
            {Object.values(dates).map((date, index) => {
                let formatDate = parseInt(date.toISOString().split('T')[0].replace(/\-/g, ''), 10);
                let active = activeDays.indexOf(formatDate) !== -1;

                if(state.isNightPrestataire === true && active === false){
                    let previousDate = new Date(date.getTime());
                    previousDate.setDate(previousDate.getDate() - 1);
                    let formatPreviousDate = parseInt(previousDate.toISOString().split('T')[0].replace(/\-/g, ''), 10);
                    let previousDateActive = activeDays.indexOf(formatPreviousDate) !== -1;
                    if(previousDateActive === true){
                        active = true;
                    }
                }
                return <Day
                    date={date}
                    active={active}
                    key={index}
                />
            }
            )}
        </div>
    )

    return (
        <div className="ing-month swiper-slide">

            <div className="ing-current-month">
                {`${moment([year, month]).locale(state.lang).format('MMMM')} ${year}`}
            </div>

            <div className="ing-month-data">
                <div className="ing-list-days">
                    {listDays.map((day, index) =>
                        <span key={index}>{day}</span>
                    )}
                </div>
                {weeksComponent}
            </div>

        </div>
    )

}