import React, { useContext, useEffect, useRef } from "react";
import { useMonthsGenerator } from "../hooks/useMonthsGenerator";
import { Month } from "./Month";
import './Calendar.css'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import { CalendarSelection } from "./CalendarSelection";
import { GlobalContext } from "../DatePicker";

export function Calendar () {

    const { state }  = useContext(GlobalContext)
    const sliderContainerRef = useRef();
    const currentDate = new Date()
    const monthsList = useMonthsGenerator(12, currentDate)
    let swiperActiveIndex = 0;

    useEffect(() => {
        const startMonth = state.selection.startDate.getMonth()
        const startYear = state.selection.startDate.getFullYear()

        let lastMonth = monthsList[monthsList.length - 1];

        getDatesInMonth(1, state.selection.startDate) // Mois courant
        
        if(lastMonth.month === startMonth && lastMonth.year === startYear){
            let prevMonthDate = new Date(state.selection.startDate.getTime());
            prevMonthDate.setMonth(startMonth - 1);

            getDatesInMonth(1, prevMonthDate) // Mois precedent
        } 
        else {
            getDatesInMonth(2, state.selection.startDate) // Mois suivant
        }

        let i;
        for (i = 0; i < monthsList.length; i++) {
            const {month, year} = monthsList[i]
            if (month === startMonth && year === startYear) {
                break;
            }
        }

        const swiper = new Swiper(sliderContainerRef.current, {
            slidesPerView: 1,
            spaceBetween: 30,
            initialSlide: i,
            breakpoints: {
                501: {
                    slidesPerView: 2
                }
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        })
        swiper.on('slideChange', function () {
            let forward = swiperActiveIndex < swiper.activeIndex;
            getDatesInMonth(swiper.activeIndex + (forward ? 2 : 1), currentDate)
            swiperActiveIndex = swiper.activeIndex;        
        })
        swiperActiveIndex = swiper.activeIndex;
    }, [])

    function getDatesInMonth (duration, date) {
        const monthListe = useMonthsGenerator(duration, date)
        const currentMonth = monthListe[monthListe.length - 1].month
        const currentYear = monthListe[monthListe.length - 1].year
        state.onMonthChange(currentMonth, currentYear)
    }

    const monthsComponent = monthsList.map((month, index) =>
        <Month
            month={month.month}
            year={month.year}
            key={index}
            weeks={month.weeks}
        />
    )

    return (
        <div ref={sliderContainerRef} className="ing-calendar swiper-container">
            <div className="swiper-wrapper">
                { monthsComponent }
            </div>
            <div className="swiper-button swiper-button-prev"/>
            <div className="swiper-button swiper-button-next"/>
            <CalendarSelection />
        </div>
    )

}