import React, { useContext } from "react";
import { GlobalContext } from "../DatePicker";
import moment from "moment";
import './CalendrierSelection.css'

export function CalendarSelection () {

    const { state } = useContext(GlobalContext)
    const { startDate, endDate, hoverDate } = state.selection

    let DatesString = {
        startLabel: state.labels.start,
        endLabel: state.labels.end,
        totalNight: null
    }
    if (startDate) {
        DatesString.startLabel = moment([startDate.getFullYear(), startDate.getMonth(), startDate.getDate()]).locale(state.lang).format('ddd Do MMM')
        if (endDate === null && hoverDate !== null) {
            DatesString.endLabel = moment([hoverDate.getFullYear(), hoverDate.getMonth(), hoverDate.getDate()]).locale(state.lang).format('ddd Do MMM')
            DatesString.totalNight = moment([hoverDate.getFullYear(), hoverDate.getMonth(), hoverDate.getDate()]).diff(moment([startDate.getFullYear(), startDate.getMonth(), startDate.getDate()]), 'days')
            DatesString.totalNight = Math.abs(DatesString.totalNight)
        }
    } else {
        if (hoverDate !== null) {
            DatesString.startLabel = moment([hoverDate.getFullYear(), hoverDate.getMonth(), hoverDate.getDate()]).locale(state.lang).format('ddd Do MMM')
        }
    }
    if (endDate) {
        DatesString.endLabel = moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]).locale(state.lang).format('ddd Do MMM')
        DatesString.totalNight = moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]).diff(moment([startDate.getFullYear(), startDate.getMonth(), startDate.getDate()]), 'days')
    }

    let nightLabel = parseInt(DatesString.totalNight) > 1 ? state.labels.nights : state.labels.night;

    return (
        <div className="ing-selection">
            <span>{`${DatesString.startLabel} - ${DatesString.endLabel} ${DatesString.totalNight !== null ? `(${state.labels.dailyfor} ${DatesString.totalNight} ${nightLabel})` : ''}`}</span>
        </div>
    )
}