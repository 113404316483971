import React, { createContext, useEffect, useReducer } from 'react'
import { DatePickerSwitch } from "./components/DatePickerSwitch";
import { Calendar } from "./components/Calendar";
import './Datepicker.css'

export const GlobalContext = React.createContext(null)

function reducer(state, action) {
    switch (action.type) {
        case 'INIT_DATES':
            const selectedView = state.views.find(view => view.selected)
            let dateInit = action.payload
            if (selectedView.type === 'SS') {
                dateInit = getNextDayOfWeek(6, dateInit)
                state.selection.startDate = dateInit
                state.selection.endDate = addDays(state.selection.startDate, 7)
            } else if (selectedView.type === 'DD') {
                dateInit = getNextDayOfWeek(0, dateInit)
                state.selection.startDate = dateInit
                state.selection.endDate = addDays(state.selection.startDate, 7)
            } else {
                state.selection.startDate = dateInit
                state.selection.endDate = addDays(state.selection.startDate, 2)
            }
            return {...state}
        case 'FILTER_DATES':
            state.datesPossibles.push(action.payload)
            return {...state}
        case 'SELECT_DATE':
            if (state.selection.startDate === null) {
                state.selection.startDate = action.payload
            } else if (state.selection.endDate == null) {
                state.selection.endDate = action.payload
                if (state.selection.endDate.getTime() < state.selection.startDate.getTime()) {
                    state.selection.endDate = state.selection.startDate
                    state.selection.startDate = action.payload
                }
            } else {
                state.selection.endDate = null
                state.selection.startDate = action.payload
            }
            return {...state}
        case 'HOVER_DATE':
            state.selection.hoverDate = action.payload
            return {...state}
        case 'SELECT_VIEW':
            state.selection.startDate = null
            state.selection.endDate = null
            state.selection.hoverDate = null
            state.views = state.views.map((view) => ({
                ...view,
                selected: action.payload.type === view.type
            }))
            return {...state}
        default:
            throw new Error(`Le type ${action.type} n'est pas pris en compte`)
    }
}

function addDays(date, days) {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

function getNextDayOfWeek(dayOfWeek, date) {
    let resultDate  = new Date(date.getTime())
    resultDate.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7)
    resultDate.setHours(0, 0, 0, 0)
    return resultDate
}


export function DatePicker({ initialState, onSelectionChange, onGetDispatch, close }) {
    const [state, dispatch] = useReducer(reducer, initialState)
    onGetDispatch(dispatch)

    function onDatepickerClick(e) {
        e.nativeEvent.stopImmediatePropagation()
    }

    useEffect(() => {
        onSelectionChange(state.selection)
    }, [JSON.stringify(state.selection.startDate), JSON.stringify(state.selection.endDate)])

    useEffect(() => {
        document.addEventListener('click', close)
        if (state.selection.startDate == null) {
            dispatch({type: 'INIT_DATES', payload: new Date()})
        }
    }, [])

    return (
        <GlobalContext.Provider value={{state, dispatch}}>
            <div onClick={onDatepickerClick}>
                <DatePickerSwitch/>
                <Calendar/>
            </div>
        </GlobalContext.Provider>
    )
}